import React, { useState } from 'react';
import './App.css';
import '../src/darkmode.css'; // Import your dark mode stylesheet
import DarkModeToggle from '../src/DarkModeToggle'; // Import your toggle component (optional)


function App() {
  const [isDarkMode, setIsDarkMode] = useState(false); // State for theme

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode'); // Apply/remove dark mode class
  };

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>  {/* Apply dark-mode class conditionally */}
      <header className="App-header">
        <h1>Compound CAD + FreeCAD/AI Consulting</h1>
        <p>World leading, cost-effective CAD + development with industry experience and client satisfaction.</p>
        <p>Fully designed in Windsor, Ontario, Canada. Priced in $USD.</p>
      </header>
      <section className="Services">
        <h2>Services</h2>
        <p>
          From expert remote CAD services and tailored FreeCAD consulting to comprehensive CAD training and cutting-edge AI integration, our Windsor, Ontario-based team delivers top-tier solutions to elevate your projects.
        </p>
        <p>Experience efficiency, precision, and expertise with Compound CAD today.</p>
      </section>
      <section className="ServiceDetails">
        {/* Replace this section with detailed service components */}
        <h2>Detailed Service Information</h2>
        <p>This section will be populated with details about each service.</p>
      </section>
      <section className="Contact">
        <h2>Contact</h2>
        <p>Email secure@compoundcad.com to learn more.</p>
      </section>
      <footer className="App-footer">
        <p>© 2024 Compound CAD</p>
        <p>
          We use cookies to provide better services and enhance your experience. By continuing to use our site, you agree to our use of cookies. 
          <a href="https://docs.google.com/document/d/17TH07e0okS7epWBi3Ggm83NckoLgnyN4XSFrdWiRPEY/edit?pli=1">Learn more.</a>
        </p>
      </footer>
      {isDarkMode && <DarkModeToggle onClick={toggleTheme} />} {/* Render toggle conditionally */}
    </div>
  );
}

export default App;
