import React, { useState } from 'react';

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <label className="dark-mode-toggle">
      <input type="checkbox" checked={isDarkMode} onChange={toggleMode} />
      <span className="slider"></span>
      <span>Dark Mode</span>
    </label>
  );
};

export default DarkModeToggle;
